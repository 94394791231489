import UpsellEditorDialog from "@/custom/dialogs/UpsellEditorDialog"

/*
    <upsell-editor-dialog
        :service="upsell_record"
        :data-version="..."
        :value="..."
        :show.sync="upsell_editing"
        @input="..."
    />
*/

const upsells_services = {
    components: {
        UpsellEditorDialog
    },
    methods: {
        edit_upsell_message_for_service (service) {
            this.$store.dispatch(
                "FETCH_RECORD",
                {
                    entity: "service",
                    id: service.id
                }
            ).then(service => {
                this.upsell_record = service
                this.upsell_editing = true
            }).catch(() => {})
        }
    },
    data () {
        return {
            upsell_record: {},
            upsell_editing: false
        }
    }
}

export default upsells_services
