<template>
    <div v-if="!!clientId" id="reports">
        <h5 class="mb-4">
            {{ translate("Reports") }}
            <base-link
                v-if="is_at_least_administrator"
                :href="`/report/sample:${client.uuid}`"
                class="btn btn-link btn-sm ml-4"
                target="smm-report-viewer"
                @click.stop
            >
                <open-icon glyph="external-link-alt" /> {{ translate("View Upcoming Report") }}
            </base-link>
        </h5>

        <data-table
            id="client-reports"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="false"
            :show-totals="false"
            :searchable="false"
            :empty-title="translate('No reports generated yet')"
            class="non-sticky"
            @sort="sort_by"
            @page="goto_page"
            @click="view"
        >
            <template
                v-slot:summary="{ row }"
            >
                <h6>{{ row.from_date | nibnut.date("MM-dd") }} - {{ row.to_date | nibnut.date("MM-dd") }}</h6>
                <div class="text-small text-gray">{{ translate("Generated on {date}", { date: nibnut_filter("nibnut.date", [row.created_at]) }) }}</div>
                <base-link
                    :href="`/report/${row.uuid}`"
                    class="btn btn-link btn-block"
                    target="smm-report-viewer"
                    @click.stop
                >
                    <open-icon glyph="external-link-alt" /> {{ translate("View") }}
                </base-link>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <span v-if="field === 'from_date'">
                    {{ row.from_date | nibnut.date("MM-dd") }} - {{ row.to_date | nibnut.date("MM-dd") }}
                </span>
                <span v-else-if="field === 'created_at'">
                    {{ row.created_at | nibnut.date }}
                </span>
                <div v-else-if="field === '_buttons'" class="text-right">
                    <base-link
                        :id="`report-${row.uuid}`"
                        :href="`/report/${row.uuid}`"
                        target="smm-report-viewer"
                        class="btn btn-link"
                        @click.stop
                    >
                        <open-icon glyph="external-link-alt" />
                    </base-link>
                    <default-button
                        flavor="link"
                        :title="translate('Re-send')"
                        class="ml-4"
                        @click.prevent.stop="resend(row)"
                    >
                        <open-icon glyph="share" :title="translate('Re-send')" />
                    </default-button>
                </div>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>
    </div>
</template>

<script>
import orderBy from "lodash/orderBy"

import { mapGetters } from "vuex"

import { is_data_table_source } from "@/nibnut/mixins"

import {
    BaseLink,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"

export default {
    name: "Report",
    mixins: [is_data_table_source],
    components: {
        BaseLink,
        DefaultButton,
        OpenIcon
    },
    methods: {
        view (row) {
            const node = document.getElementById(`report-${row.uuid}`)
            if(node) node.click()
        },
        resend (row) {
            this.$store.dispatch("RECORD_ACTION", {
                entity: "report",
                id: row.id,
                action: "resend",
                method: "put"
            }).then(() => {
                this.$success(this.translate("Report sent!"))
            }).catch(error => {
                this.$error(error.message)
            })
        }
    },
    computed: {
        ...mapGetters(["entity_records", "entity_record"]),
        state_identifier () {
            return `client-${this.clientId}-reports`
        },
        client () {
            return this.entity_record("client", this.clientId)
        },
        rows () {
            if(!this.clientId) return []
            const client = this.client
            return orderBy(this.entity_records("report").filter(report => report.client_id === client.id), ["to_date", "from_date"], ["desc", "desc"])
        }
    },
    props: {
        clientId: {
            required: true
        }
    },
    data () {
        return {
            columns: {
                from_date: { label: "Period", sort: "desc", cell_class: "no-wrap" },
                created_at: { label: "Generated", sort: null, cell_class: "no-wrap" },
                _buttons: { label: " ", sort: false }
            },
            default_state: {
                per_page: 3,
                page: 1,
                sort_by: "from_date",
                sort_dir: "desc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            }
        }
    }
}
</script>
