<template>
    <div v-if="!!clientId" id="client-services">
        <h5
            :class="{ 'text-bold': is_missing_report_recipients, 'text-error': is_missing_report_recipients }"
            class="mb-4"
        >
            {{ translate("Services") }}
            <span v-if="is_missing_report_recipients" class="text-uppercase text-small">
                <open-icon glyph="exclamation-circle" />
                {{ translate("There are no recipients for reports!") }}
            </span>
        </h5>

        <div class="columns">
            <div
                v-for="row in filtered_rows"
                :key="row.id"
                :class="{ 'col-3': !show_service_statistics(row), 'col-6': !!show_service_statistics(row), 'col-md-4': !show_service_statistics(row), 'col-md-12': !!show_service_statistics(row) }"
                class="column col-sm-12 mb-4"
            >
                <div class="card text-center">
                    <div class="columns">
                        <div
                            :class="{ 'col-12': !show_service_statistics(row), 'col-5': !!show_service_statistics(row), 'col-md-12': !show_service_statistics(row), 'col-md-6': !!show_service_statistics(row) }"
                            class="column col-sm-12"
                        >
                            <div class="card-header">
                                <base-link
                                    v-if="!!row.client_url"
                                    :href="client_service_url(row)"
                                    target="_blank"
                                >
                                    <img v-if="row.slug === 'bit-defender'" src="@/assets/img/Bitdefender-Logo-BW-web.png" />
                                    <img v-else-if="row.slug === 'uptime-robot'" src="@/assets/img/uptimerobot-logo-dark.svg" />
                                    <img v-else-if="row.slug === 'zen-desk'" src="@/assets/img/zendesk-logo.svg" />
                                    <img v-else-if="row.slug === 'watchman'" src="@/assets/img/watchman.png" />
                                    <img v-else-if="row.slug === 'zero-spam'" src="@/assets/img/zerospam_logo-850x-opti.png" />
                                    <img v-else-if="row.slug === 'dns-filter'" src="@/assets/img/dns-filter.svg" />
                                    <img v-else-if="row.slug === 'background-backup'" src="@/assets/img/background-backup.jpeg" />
                                    <img v-else-if="row.slug === 'addigy'" src="@/assets/img/addigy_logo.svg" />
                                    <img v-else-if="row.slug === 'hexnode'" src="@/assets/img/hexnode_logo.png" />
                                    <img v-else-if="row.slug === 'jamf'" src="@/assets/img/jamf_logo.svg" />
                                    <img v-else-if="row.slug === 'sherweb'" src="@/assets/img/sherweb_logo.svg" />
                                </base-link>
                                <img v-else-if="row.slug === 'bit-defender'" src="@/assets/img/Bitdefender-Logo-BW-web.png" />
                                <img v-else-if="row.slug === 'uptime-robot'" src="@/assets/img/uptimerobot-logo-dark.svg" />
                                <img v-else-if="row.slug === 'zen-desk'" src="@/assets/img/zendesk-logo.svg" />
                                <img v-else-if="row.slug === 'watchman'" src="@/assets/img/watchman.png" />
                                <img v-else-if="row.slug === 'zero-spam'" src="@/assets/img/zerospam_logo-850x-opti.png" />
                                <img v-else-if="row.slug === 'dns-filter'" src="@/assets/img/dns-filter.svg" />
                                <img v-else-if="row.slug === 'background-backup'" src="@/assets/img/background-backup.jpeg" />
                                <img v-else-if="row.slug === 'addigy'" src="@/assets/img/addigy_logo.svg" />
                                <img v-else-if="row.slug === 'hexnode'" src="@/assets/img/hexnode_logo.png" />
                                <img v-else-if="row.slug === 'jamf'" src="@/assets/img/jamf_logo.svg" />
                                <img v-else-if="row.slug === 'sherweb'" src="@/assets/img/sherweb_logo.svg" />
                            </div>
                            <div class="card-body">
                                <div v-if="!client_service(row.id).remote_id">
                                    <default-button
                                        color="success"
                                        @click.prevent.stop="toggle(row)"
                                    >
                                        {{ translate("Activate") }}
                                    </default-button>
                                    <br />
                                    <default-button
                                        :color="!!client_service(row.id).upsell ? 'primary' : 'regular'"
                                        class="mt-4"
                                        @click.prevent.stop="edit_upsell_message_for_service(row)"
                                    >
                                        <open-icon :glyph="!!client_service(row.id).upsell_message ? 'comment-dots' : 'comment-alt'" />
                                        {{ translate("Upsell") }}
                                    </default-button>
                                </div>
                                <div v-else class="remote-info">
                                    <div class="text-truncated">{{ client_service(row.id).remote_name }}</div>
                                    <div class="text-gray text-small text-truncated">{{ client_service_identifier(row) }}</div>
                                </div>
                            </div>
                            <div
                                v-if="client_service(row.id).remote_id"
                                class="card-footer"
                            >
                                <div
                                    :class="{ 'd-invisible': !client_service(row.id).remote_id }"
                                    class="columns"
                                >
                                    <div
                                        class="column"
                                    >
                                        <default-button
                                            flavor="link"
                                            color="error"
                                            size="sm"
                                            @click.prevent.stop="toggle(row)"
                                        >
                                            {{ translate("Deactivate") }}
                                        </default-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <client-service-graph
                            v-if="show_service_statistics(row)"
                            :id="`service-graph-${row.id}`"
                            :client-service="client_service(row.id)"
                            class="column col-7 col-md-6 hide-sm"
                        />
                    </div>
                </div>
            </div>
        </div>

        <client-service-report-dialog
            v-if="!!client"
            :show.sync="viewing"
            :client="client"
            :service="focused_service"
        />
        <client-service-link-dialog
            v-if="!!client"
            :show.sync="linking"
            :client="client"
            :service="focused_service"
        />
        <upsell-editor-dialog
            :service="upsell_record"
            :data-version="client_service(upsell_record.id).id || 0"
            :active="client_service(upsell_record.id).upsell || false"
            :value="client_service(upsell_record.id).upsell_message"
            :default-value="upsell_record.upsell_message"
            :toggable="true"
            :show.sync="upsell_editing"
            @input="save_upsell_message"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex"

import { is_data_table_source, handles_saving } from "@/nibnut/mixins"
import { upsells_services } from "@/custom/mixins"

import {
    BaseLink,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"
import {
    ClientServiceLinkDialog,
    ClientServiceReportDialog
} from "@/custom/dialogs"
import ClientServiceGraph from "./ClientServiceGraph"

export default {
    name: "ClientServices",
    mixins: [is_data_table_source, handles_saving, upsells_services],
    components: {
        BaseLink,
        DefaultButton,
        OpenIcon,
        ClientServiceLinkDialog,
        ClientServiceReportDialog,
        ClientServiceGraph
    },
    methods: {
        client_service (service_id) {
            const client = this.client
            if(!client) return {}
            return this.entity_records("client_service").find(client_service => {
                return (client_service.client_id === client.id) && (client_service.service_id === service_id)
            }) || {}
        },
        client_service_url (service) {
            return service.client_url.replace(/%id%/g, this.client.remote_id)
        },
        client_service_identifier (service) {
            let identifier = this.client_service(service.id).remote_id
            if(service.slug === "jamf") identifier = identifier ? identifier.replace(/:.*$/, "") : identifier
            return identifier
        },
        toggle (service) {
            const client_service = this.client_service(service.id)
            // ****** MULTIPLE CLIENT_SERVICES: deactivate ALL client_services records
            if(!!client_service.remote_id && !!client_service.id) this.save_field_for_record_id("client_service", client_service.id, null, "remote_id")
            else {
                this.focused_service = service
                this.linking = true
            }
        },
        syncing (row) {
            return this.syncing_rows.indexOf(row.id) >= 0
        },
        view (service) {
            this.focused_service = service
            this.viewing = true
        },
        show_service_statistics (service) {
            return !!this.client_service(service.id).remote_id && this.service_statistics(service)
        },
        service_statistics (service) {
            const client_service = this.client_service(service.id)
            if(!client_service || !client_service.statistics) return null
            return client_service.statistics
        },
        save_upsell_message (value, field) {
            const id = this.client_service(this.upsell_record.id).id
            if(id) this.save_field_for_record_id("client_service", id, value, field)
            else {
                this.save_data_for_record_id(
                    "client_service",
                    id,
                    {
                        client_id: this.client.id,
                        service_id: this.upsell_record.id,
                        [field]: this.standardize_field_save_value(value, field)
                    }
                )
            }
        }
    },
    computed: {
        ...mapGetters(["entity_records", "entity_record"]),
        rows () {
            return this.entity_records("service").filter(service => !!service.active)
        },
        client () {
            return this.entity_record("client", this.clientId)
        },
        is_missing_report_recipients () {
            const client = this.client
            return !this.reportRecipientsCount && !!client && !!this.entity_records("client_service").find(client_service => {
                return (client_service.client_id === client.id)
            })
        }
    },
    props: {
        clientId: {
            required: true
        },
        reportRecipientsCount: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            columns: {
                _toggle: { label: " ", sort: false },
                name: { label: "Service", sort: false, cell_class: "no-wrap" },
                _buttons: { label: " ", sort: false }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0
            },

            linking: false,
            syncing_rows: [],

            viewing: false,

            focused_service: {}
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/app";

#client-services {
    .card {
        height: 100%;

        & .columns {
            height: 100%;

            & > .column {
                display: flex;
                flex-direction: column;
                img {
                    height: 1.2rem;
                }
                .card-body {
                    min-height: 3.5rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .remote-info {
                        display: flex;
                        flex-direction: column;

                        .text-truncated {
                            flex: 1 1 37px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            line-height: 1.2em;
                        }
                        .text-gray {
                            flex: 0 0 auto;
                        }
                    }
                    .card-graph {
                        padding: 0;
                    }
            }
            }
        }
    }
    td {
        vertical-align: top;
    }
}
</style>
