<template>
    <page
        id="client-editor"
        :title="page_title"
        :back-navigation-fallback="default_back"
        :status="page_status"
        :waiting="waiting"
        :narrow="true"
        @statused="statused"
    >
        <base-form
            v-if="!!edited_record"
            :has-required-fields="true"
        >
            <div class="columns">
                <div
                    :class="{ 'col-4': !!edited_record.id,'col-8': !edited_record.id }"
                    class="column col-sm-12"
                >
                    <form-input
                        id="name"
                        name="name"
                        v-model="edited_record.name"
                        :required="true"
                        :disabled="true"
                    >
                        <template v-slot:label>{{ $root.translate("Name") }}</template>
                    </form-input>
                    <form-input
                        id="abbreviation"
                        name="abbreviation"
                        v-model="edited_record.abbreviation"
                        :required="false"
                        :disabled="true"
                        :saving="saving('abbreviation')"
                        :error="has_error('abbreviation')"
                        @input="save"
                    >
                        <template v-slot:label>{{ $root.translate("Acronym") }}</template>
                    </form-input>
                    <form-input
                        id="website"
                        name="website"
                        v-model="edited_record.website"
                        :required="false"
                        :disabled="true"
                    >
                        <template v-slot:label>{{ $root.translate("Website") }}</template>
                        <template v-slot:right_addon>
                            <base-link
                                v-if="!!edited_record.website"
                                :href="edited_record.website"
                                target="_blank"
                                class="btn"
                                @click="$event.target.blur()"
                            >
                                <open-icon glyph="external-link-alt" />
                            </base-link>
                        </template>
                    </form-input>
                    <div class="columns">
                        <div class="column col-6 col-sm-12">
                            <form-input
                                id="phone"
                                name="phone"
                                v-model="edited_record.phone"
                                :required="false"
                                :disabled="true"
                            >
                                <template v-slot:label>{{ $root.translate("Phone") }}</template>
                            </form-input>
                        </div>
                        <div class="column col-6 col-sm-12">
                            <form-input
                                id="fax"
                                name="fax"
                                v-model="edited_record.fax"
                                :required="false"
                                :disabled="true"
                            >
                                <template v-slot:label>{{ $root.translate("Fax") }}</template>
                            </form-input>
                        </div>
                    </div>
                    <form-textbox
                        id="comments"
                        name="comments"
                        v-model="edited_record.comments"
                        :required="false"
                        :disabled="true"
                    >
                        <template v-slot:label>{{ $root.translate("Comments") }}</template>
                    </form-textbox>

                    <h6 v-if="!!edited_record.id" class="mt-4">{{ $root.translate("Logo") }}</h6>
                    <div
                        v-if="!!edited_record.id"
                        class="nibnut-aspect-ratio-box nibnut-ratio-golden"
                    >
                        <div>
                            <image-upload-input
                                v-if="!!edited_record"
                                name="image_url"
                                :url="image_url('image_url')"
                                :alt="edited_record.name || ''"
                                :accepts="upload_accepts('image_url')"
                                :uploading="!!uploading.image_url"
                                :uploaded="uploaded.image_url||0"
                                :error="has_error('image_url')"
                                @upload="upload_file_list('image_url', $event)"
                                @clear="confirm_clear_file('image_url')"
                            />
                        </div>
                    </div>
                </div>
                <div
                    v-if="!!edited_record.id"
                    class="column col-8 col-sm-12"
                >
                    <contacts
                        :client="edited_record"
                        :has-active-services="has_active_services"
                        class="mb-4"
                    />
                    <!--
                    <billables
                        :client-id="edited_record.id"
                        class="mb-4"
                    />
                    //-->
                    <reports
                        :client-id="edited_record.uuid || edited_record.id"
                    />
                </div>
                <div
                    v-else
                    class="column col-4 col-sm-12"
                >
                    <h6 class="mt-4">{{ $root.translate("Logo") }}</h6>
                    <div
                        class="nibnut-aspect-ratio-box nibnut-ratio-golden"
                    >
                        <div>
                            <image-upload-input
                                v-if="!!edited_record"
                                name="image_url"
                                :url="image_url('image_url')"
                                :alt="edited_record.name || ''"
                                :accepts="upload_accepts('image_url')"
                                :uploading="!!uploading.image_url"
                                :uploaded="uploaded.image_url||0"
                                :error="has_error('image_url')"
                                @upload="upload_file_list('image_url', $event)"
                                @clear="confirm_clear_file('image_url')"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <client-services
                :client-id="edited_record.uuid || edited_record.id"
                :report-recipients-count="edited_record.report_recipients_count"
                class="mt-8"
            />

            <beta-testers-input
                v-if="edited_record"
                id="active-beta-tests"
                :value="edited_record.active_beta_tests"
                :required="false"
                :saving="saving('active_beta_tests')"
                :error="has_error('active_beta_tests')"
                class="mt-8"
                @input="save"
            >
                <h5 class="mb-4">
                    {{ translate("Beta Testing") }}
                </h5>
            </beta-testers-input>

            <div
                v-if="!!edited_record && !edited_record.id"
                class="text-center mt-8"
            >
                <default-button
                    :disabled="!!creating_record"
                    class="mx-2"
                    @click.prevent="$router.go(-1)"
                >
                    {{ $root.translate("Cancel") }}
                </default-button>
                <default-button
                    color="primary"
                    :waiting="!!creating_record"
                    :disabled="!!creating_record"
                    class="mx-2"
                    @click.prevent="record_save"
                >
                    {{ $root.translate("Create") }}
                </default-button>
            </div>
            <!--
            <div
                v-if="!!edited_record && !!edited_record.id"
                class="text-center mt-8"
            >
                <default-button
                    flavor="link"
                    color="error"
                    size="sm"
                    @click.prevent="confirm_record_delete"
                >
                    {{ $root.translate("Delete Client") }}
                </default-button>
            </div>
            //-->
        </base-form>

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </page>
</template>

<script type="text/javascript">
import { is_record_editor_page, handles_uploads } from "@/nibnut/mixins"

import {
    BaseForm,
    FormInput,
    FormTextbox,
    ImageUploadInput,
    BetaTestersInput,
    BaseLink,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"
import Contacts from "./Contacts"
// import Billables from "./Billables"
import ClientServices from "./ClientServices"
import Reports from "./Reports"

export default {
    mixins: [is_record_editor_page, handles_uploads],
    components: {
        BaseForm,
        FormInput,
        FormTextbox,
        ImageUploadInput,
        BetaTestersInput,
        BaseLink,
        DefaultButton,
        OpenIcon,
        Contacts,
        ClientServices,
        Reports
    },
    methods: {
        image_url (name) {
            if(this.holding_mode && !!this.held_images[name]) return this.held_images[name].src
            return this.edited_record[name]
        },
        upload_accepts (name, filename = null) {
            if(filename) return !!filename.match(/\.(png|jpe?g|svg)$/i)
            return "image/jpeg,image/png,image/svg,.jpg,.jpeg,.png,.svg"
        },
        upload_data (mode) {
            return {
                entity: this.entity,
                id: this.record_id
            }
        },
        upload_held (name) {
            this.generate_held_image(name)
        },
        upload_attach_error (name, error) {
            this.add_error(name, error.message)
        },
        upload_detach_error (name, error) {
            this.add_error(name, error.message)
        },
        upload_detached (name, response) {
            this.done_confirming()
        },
        upload_held_files () {
            if(!!this.edited_record && !!this.edited_record.id) this.upload_file("image_url", this.held.image_url)
        },
        record_save () {
            const edited_record = this.edited_record
            const is_new = !edited_record.id
            if(is_new) this.creating_record = true
            return this.save_data_for_record_id(this.entity, edited_record.id || 0, edited_record).then(record => {
                this.creating_record = false
                this.shell_record = null
                this.record_id = record.uuid

                const wrapup = () => {
                    this.$router.replace({ name: `${this.entity}.edit`, params: { id: record.uuid || record.id } })
                }
                if(this.held.image_url) this.upload_file("image_url", this.held.image_url).then(wrapup)
                else wrapup()
            })
        },
        confirm_record_delete () {
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete {name}", { name: this.edited_record.name }),
                    message: this.$root.translate("Do you really want to delete this client? It will also be deleted in Accelo and all other tool/service this customer is registered for. There is no undo..."),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "clear-file") this.clear_file()
            else if(this.confirming === "delete-record") this.record_delete()
            else this.done_confirming()
        },
        record_deleted () {
            this.$router.push({ name: "client.list" })
        }
    },
    computed: {
        default_back () {
            return { title: this.$root.translate(this.entityName, {}, 2), href: { name: "client.list" } }
        },
        holding_mode () {
            return !this.edited_record || !this.edited_record.id
        },
        has_active_services () {
            if(!this.edited_record) return false
            return !!this.entity_records("client_service").find(client_service => {
                return (client_service.client_id === this.edited_record.id) && !!client_service.remote_id
            })
        }
    },
    data () {
        return {
            entity: "client",
            entityName: "Client:::Clients",
            relation_ids: ["service", "client_service", "report"]
        }
    }
}
</script>
