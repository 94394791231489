<template>
    <div v-if="!!service_statistics" class="client-service-graph">
        <div class="btn-group">
            <default-button
                v-for="range in ranges"
                :key="range.value"
                :active="current_range === range.value"
                size="sm"
                @click.prevent.stop="current_range = range.value"
            >
                {{ range.label }}
            </default-button>
        </div>
        <apexchart
            type="area"
            :options="chart_options"
            :series="chart_data"
        />
    </div>
</template>

<script>
import {
    DefaultButton
} from "@/nibnut/components"
import VueApexCharts from "vue-apexcharts"

export default {
    name: "ClientServiceGraph",
    components: {
        DefaultButton,
        Apexchart: VueApexCharts
    },
    computed: {
        chart_options () {
            let categories = []
            const statistics = this.service_statistics
            if(statistics) {
                const sku = Object.keys(statistics)[0]
                categories = Object.keys(statistics[sku][this.current_range])
            }
            return {
                chart: {
                    id: this.id,
                    height: "100%",
                    width: "98%",
                    type: "area",
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false
                },
                fill: {
                    opacity: 0.3
                },
                stroke: {
                    width: 1
                },
                xaxis: {
                    type: "category",
                    categories,
                    tickAmount: 7
                },
                yaxis: {
                    tickAmount: 3
                }
            }
        },
        service_statistics () {
            // { <sku>: { <range_id>: { <date>: <count>, ... } }}
            if(!this.clientService || !this.clientService.statistics) return null
            return this.clientService.statistics
        },
        chart_data () {
            const statistics = this.service_statistics
            return Object.keys(statistics).map(sku => {
                return {
                    name: sku,
                    data: Object.values(statistics[sku][this.current_range])
                }
            })
        },
        ranges () {
            return [
                { value: "12months", label: this.translate("1 year") },
                { value: "6months", label: this.translate("6 months") },
                { value: "3months", label: this.translate("3 months") },
                { value: "1month", label: this.translate("1 month") }
            ]
        }
    },
    props: {
        id: {
            type: String,
            required: true
        },
        clientService: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            current_range: "6months"
        }
    }
}
</script>

<style lang="scss">
.client-service-graph {
    position: relative;

    .btn-group {
        position: absolute;
        top: 0.1rem;
        right: 0.5rem;
        z-index: 1;

        .btn.btn-sm {
            flex: 0 0 auto;
            font-size: 0.5rem;
            height: 1rem;
            padding: 0.025rem 0.3rem;
            line-height: 0.6rem;
        }
    }
}
</style>
