<template>
    <div id="client-contacts">
        <h6 class="mb-4">{{ $root.translate("Contacts") }}</h6>
        <data-table
            id="contacts-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="false"
            @sort="sort_by"
            @search="search"
            @page="goto_page"
            @click="edit_row"
        >
            <template
                v-slot:buttons
            >
                <default-button
                    flavor="link"
                    :waiting="resyncing"
                    :title="$root.translate('Re-sync all client\'s contacts')"
                    class="ml-2"
                    @click="resync"
                >
                    <open-icon glyph="sync" />
                </default-button>
            </template>
            <template
                v-slot:summary="{ row }"
            >
                <h6>{{ row_name(row) }}</h6>
                <div v-if="row.position" class="text-small text-gray">{{ row.position }}</div>
                <mailto-link
                    v-if="row.mobile"
                    :value="row.mobile"
                    filter="telto"
                    @click.native.stop
                >
                    {{ row.mobile }}
                </mailto-link>
                <mailto-link
                    v-if="row.email"
                    :value="row.email"
                    @click.native.stop
                >
                    {{ row.email }}
                </mailto-link>
                <default-button
                    :flavor="row.is_report_recipient ? 'normal' : 'link'"
                    :color="row.is_report_recipient ? 'primary' : 'light'"
                    size="sm"
                    :title="row.is_report_recipient ? $root.translate('Receives reports') : $root.translate('Does not receive reports')"
                    :block="true"
                    :waiting="saving_report_recipients.indexOf(row.id) >= 0"
                    :disabled="hasActiveServices && (client.report_recipients_count == 1)"
                    @click.prevent.stop="toggle_report_recipient(row)"
                >
                    <open-icon
                        :glyph="row.is_report_recipient ? 'check' : 'times'"
                    />
                    <span v-if="row.is_report_recipient">{{ $root.translate('Receives reports') }}</span>
                    <span v-else>{{ $root.translate('Does not receive reports') }}</span>
                </default-button>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div v-if="field === 'first_name'">
                    {{ row_name(row) }}
                    <div v-if="row.position" class="text-small text-gray">{{ row.position }}</div>
                </div>
                <div v-else-if="field === 'email'">
                    <mailto-link
                        v-if="row.mobile"
                        :value="row.mobile"
                        filter="telto"
                        @click.native.stop
                    >
                        {{ row.mobile }}
                    </mailto-link>
                    <mailto-link
                        v-else
                        :value="row.email"
                        @click.native.stop
                    >
                        {{ row.email }}
                    </mailto-link>
                    <div v-if="row.mobile" class="text-small">
                        <mailto-link
                            :value="row.email"
                            @click.native.stop
                        >
                            {{ row.email }}
                        </mailto-link>
                    </div>
                </div>
                <form-toggle-input
                    v-else-if="field === 'is_report_recipient'"
                    :id="`is_report_recipient-${row.id}`"
                    name="is_report_recipient"
                    type="checkbox"
                    size="sm"
                    :value="row.is_report_recipient"
                    :required="false"
                    :editable="!hasActiveServices || !row.is_report_recipient || (client.report_recipients_count != 1)"
                    @input="toggle_report_recipient(row)"
                    @click.native.stop
                />
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>

        <contact-editor-dialog
            :show.sync="editing"
            :client="client"
            :contact-id="editor_record.uuid"
            @created="reset"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import { is_remote_data_table_source, handles_saving, string_utilities } from "@/nibnut/mixins"

import { MailtoLink, FormToggleInput, DefaultButton, OpenIcon } from "@/nibnut/components"
import { ContactEditorDialog } from "@/custom/dialogs"

export default {
    name: "Contacts",
    mixins: [is_remote_data_table_source, handles_saving, string_utilities],
    components: {
        MailtoLink,
        FormToggleInput,
        DefaultButton,
        OpenIcon,
        ContactEditorDialog
    },
    watch: {
        "client.id": "reset",
        editing: "reset_editor"
    },
    methods: {
        pre_load () {
            this.set_state_value("client_id", this.client.id)
        },
        row_name (row) {
            return this.string_concat(" ", row.first_name, row.last_name)
        },
        sort_rows (rows) {
            if(this.state.sort_by) {
                return orderBy(rows, (row) => {
                    if(this.state.sort_by === "name") return this.row_name(row)
                    if(!row[this.state.sort_by]) {
                        if(this.state.sort_dir === "asc") return "999999999"
                        return "000000000"
                    }
                    return row[this.state.sort_by]
                }, this.state.sort_dir)
            }
            return rows
        },
        toggle_report_recipient (row) {
            if(
                row.is_report_recipient &&
                (this.client.report_recipients_count <= 1)
            ) return
            if(this.saving_report_recipients.indexOf(row.id) < 0) this.saving_report_recipients.push(row.id)
            this.save_data_for_record_id(this.entity, row.id, {
                client_id: row.client_id,
                is_report_recipient: !row.is_report_recipient,
                fields: this.fields,
                relation_ids: ["client"]
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                const index = this.saving_report_recipients.indexOf(row.id)
                if(index >= 0) this.saving_report_recipients.splice(index, 1)
            })
        },
        edit_row (row) {
            this.editor_record = row
            this.editing = true
        },
        reset_editor () {
            if(!this.editing) this.editor_record = {}
        },
        resync () {
            this.resyncing = true
            this.$store.dispatch(
                "RECORDS_ACTION",
                {
                    entity: "contact",
                    action: "resync",
                    data: {
                        ...this.state,
                        fields: this.fields,
                        relation_ids: this.relation_ids
                    }
                }
            ).then(() => {
                this.refresh()
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.resyncing = false
            })
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        can_reload () {
            return this.profile_id && this.client.id
        },
        state_identifier () {
            return `client-${this.client.id}-contacts`
        },
        fields () {
            return ["fieldset::list", "ns::client;fieldset::default"]
        }
    },
    props: {
        client: {
            type: Object,
            required: true
        },
        hasActiveServices: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            entity: "contact",
            entityName: "contact:::contacts",
            columns: {
                first_name: { label: "Name", sort: "asc", type: "alpha" },
                email: { label: "Contact", sort: null, type: "alpha" },
                is_report_recipient: { label: "Reports", sort: null, type: "amount" }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                client_id: 0
            },

            editing: false,
            editor_record: {},

            resyncing: false,
            setting_default_client: false,
            saving_report_recipients: []
        }
    }
}
</script>

<style lang="scss">
#client-contacts {
    table.table {
        td {
            vertical-align: top;
        }
    }
}
</style>
